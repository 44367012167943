import React from 'react';
import OnboardingSidebar from '../Reusables/OnboardingSidebar/OnboardingSidebar';
import SignUpPage from './SignUpPage/SignUpPage';
import './SignUp.scss'
const SignUp = () => {
  return (
    <div className='main_signup_container'> 
        <OnboardingSidebar/>
        <SignUpPage/>
    </div>
  )
}

export default SignUp
