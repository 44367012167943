import React from 'react';
import './TermsandPrivacy.scss'
const TermsandPrivacy = () => {
  return (
    <div style={{marginTop:'30px'}}>
        <p className='termsandprivacy'>By signing up, you agree to our <span> <a href='https://sonaroid.io/termsofservice'>Terms and Conditions</a></span> and
            <span> <a href='https://sonaroid.io/privacypolicy'>Privacy Policy</a></span></p>
    </div>
  )
}

export default TermsandPrivacy
