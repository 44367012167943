import React from 'react'
import CircularProgress from '@mui/material/CircularProgress';

export default function Spinner({color}) {
    return (
        <div>
            <CircularProgress style={{ color: color }} />
        </div>
    )
}
