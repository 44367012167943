import React, { useState } from 'react';
import OnboardingSidebar from '../../Onboarding/Reusables/OnboardingSidebar/OnboardingSidebar';
import back from '../../../assets/backicon.svg';
import BaseButton from '../../Onboarding/Reusables/BaseButton/BaseButton';
import imgone from '../../../assets/iconpadlock.svg';
import imgtwo from '../../../assets/iconeyepasswor.svg';
import '../ForgotPass/ForgotPass.scss';
import axios from "axios";
import { useSelector } from 'react-redux';
const ResetPass = () => {

  const baseUrl = process.env.REACT_APP_BASE_URL;
  const userData = useSelector(state => state.userLogin.userInfo);
  const token = userData.token;
  const [loading,setLoading] = useState(false);
  const [password,setPassword]=useState('')
  const handleSubmit=(e)=>{
    e.preventDefault()
    let body={
      "password":password
    }
    try {
      setLoading(true)
      const config = {
        headers: {
          "Content-Type": "application/json",
           'Authorization': `Bearer ${token}`
        },
      };

      axios
        .post(`${baseUrl}auth/update-password`,body,config)
        .then((res) => {
            console.log(res)
            alert("Password change successful")
            setLoading(false)
        })
        .catch((error) => {
          
          setLoading(false)
          if (error.response) {
            alert(error.response.data.message);
          } else if (error.request) {
            alert("Check your connection. Unable to reach the server.");
          } else {
            alert("An error occurred. Please try again later.");
          }
        });
    }
    catch (error) {
      console.log("error", error);
    }
  }
  return (
    <div className='onboard_cont'>

      <div>
         <OnboardingSidebar/>
      </div>

      <div className='forgot_pass_cont'>
          <div>
            <img 
            style={{marginBottom:'32px'}}
              src={back} alt="icon" />
          </div>

          <h1>Set a new password</h1>
          <p>Create a new secure password.</p>

          
         <form onSubmit={handleSubmit}>
             <div 
                style={{marginBottom:'20px'}}
                className='signup_pass'>
                <h4>Create password</h4>
                <div>
                  <img src={imgone} alt="icon" />
                  <input
                      required
                      type="password" 
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      />
                  <img src={imgtwo} alt="icon" />
                </div>
                <p className='helper_text'>8-20 characters, no spaces</p>
            </div>
            {loading ?(
            <p style={{textAlign:'center'}}>Please wait...</p>
            ):(
            <BaseButton
                title={"Change Password"}
                width={"100%"}
                height={"44px"}
                padding={"0.5em 1em"}
            />)}
            </form>
      </div>
      
    </div>
  )
}

export default ResetPass
