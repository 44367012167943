import React from 'react';
import './ProfileCard.scss';

import BaseButton from '../BaseButton/BaseButton';

const ProfileCard = ({
    callSubmit,
    accountType,
    accountinfo,
    accountloc,
    accountname,
    preferences,
    datejoined,
    subscription,
    subtype,
    cardImage

    }) => {

        function capitalizeFirstLetter(string) {
            if (!string) return string; // Return the string if it's empty or undefined
            return string.charAt(0).toUpperCase() + string.slice(1);
        }
  return (
    <div className='profile_card_container'>
        
        <div className="profile_card_header"> 
            <h1>Complete Set-Up</h1>
            
            
            <BaseButton
            title={"Submit"}
            width={"88px"}
            height={"44px"}
            padding={"0.5em 1em"}
            onClick={()=>callSubmit()}
        />
        </div>

        <div className='profile_card_profile_cont'>
            <h4>Your Profile</h4>

            <div className='profile_card_profile'>
                <div className='profile_card_name'>
                    <div>
                    <img src={cardImage} alt="" />
                    </div>
                   
                    <h4>{accountname}</h4>
                    <p>{accountinfo}</p>
                    <p>{accountloc}</p>
                </div>

                <div className='profile_card_content'>
                    <div className='profile_card_grid'>
                        <div >
                            <p>Account type</p>
                            <h4 className="color_consumer">{capitalizeFirstLetter(accountType)}</h4>
                        </div>

                        <div>
                            <p>{subscription}</p>
                            <h4>{subtype}</h4>
                        </div>
                        
                        <div>
                            <p>Date Joined</p>
                            <h4>{datejoined}</h4>
                        </div>
                    </div>

                    <div className='profile_card_pref'>
                        <p>Preferences</p>
                        <li>{preferences}</li>
                    </div>
                </div>

            </div>
        </div>

    </div>
  )
}

export default ProfileCard
