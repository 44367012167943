import React, { useState} from 'react';
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import './SelectDropdown.scss'
function CustomDropdown({ options,
  selectedOption,
   placeholder,
   heightFor,
   paddingFor,
   heightForTwo,
   onSelect,
  marginFor }) {
  const [isOpen, setIsOpen] = useState(false);
 
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };


  const useStyle={
    height:heightFor
  }
  const useStyleTwo={
    padding:paddingFor,

  }
  const useStyleThree={
    height:heightForTwo,
    marginTop:marginFor
  }
  const handleSelectChange = (option) => {
    onSelect(option); 
    setIsOpen(false); 
  };

  return (
        <div style={useStyle} className='custom_select_container'>
            <div style={useStyleTwo} className='custom_selected_option' onClick={()=>toggleDropdown()}>
                <p className='custom_selected_placeholder'> {selectedOption || placeholder}</p>
           
                
            {isOpen ? <IoIosArrowUp style={{ fontSize: '20px',color:'#B8B3A7' }} /> :
             <IoIosArrowDown style={{ fontSize: '20px',color:'#B8B3A7' }} />}
            </div>
            {isOpen && (
          
                <ul style={useStyleThree} className='custom_option_cont'>
                    
                        {options.map((option) => (
                        <li key={option} 
                        onClick={() => handleSelectChange(option)}
                        className='custom_list'
                        >
                            {option}
                            
                        </li>
                        ))}
                
                    
                </ul>
                
            )}
        </div>
  );
}

export default CustomDropdown;
