import { combineReducers, createStore, applyMiddleware } from 'redux';
import { thunk } from 'redux-thunk';
import { composeWithDevTools } from '@redux-devtools/extension';
import { userLoginReducer } from './userReducers';

// Combine reducers
const reducer = combineReducers({
    userLogin: userLoginReducer
});

// Get user info from localStorage
const userInfoFromStorage = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : {};

// Define initial state
const initialState = {
    userLogin: { userInfo: userInfoFromStorage }
};

// Middleware
const middleware = [thunk];

// Create Redux store
const store = createStore(
    reducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middleware))
);

// Subscribe to Redux store changes
store.subscribe(() => {
    // Update localStorage with the latest state from Redux store
    localStorage.setItem('userInfo', JSON.stringify(store.getState().userLogin.userInfo));
});

export default store;
