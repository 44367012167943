export const formatDateString=(dateString)=>{
    // Create a Date object from the ISO 8601 string
    const date = new Date(dateString);
    
    // Array of month names
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    
    // Get month and day from the Date object
    const month = months[date.getMonth()];
    const day = String(date.getDate()).padStart(2, '0'); // Ensure day is two digits
    const year = date.getFullYear();
    
    // Format the date as "Month Day, Year"
    return `${month} ${day}, ${year}`;
}

