import React from 'react';
import './BaseButton.scss';
const BaseButton = ({title,height,width,padding,onClick,disabled}) => {

const useStyle={
    height:height,
    width:width,
    padding:padding
}
  return (
    <div className='primary_btn_onboard'>
      <button 
      onClick={onClick}
      type="submit"
      disabled={disabled}
      style={useStyle}>
        {title}
      </button>
    </div>
  )
}

export default BaseButton
