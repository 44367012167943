import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SignUp from './components/Onboarding/SignUp/SignUp';
import EmailVerification from './components/Onboarding/EmailVerification/EmailVerification';
import MoreAboutYou from './components/Onboarding/MoreAboutYou/MoreAboutYou';
// import CompleteConsumer from './components/Onboarding/CompleteConsumer/CompleteConsumer';
// import CompleteContributor from './components/Onboarding/CompleteContributor/CompleteContributor';
import Login from './components/Authentication/Login/Login';
import ForgotPass from './components/Authentication/ForgotPass/ForgotPass';
import ResetPass from './components/Authentication/ResetPass/ResetPass';

function App() {
  return (
    <Router>
      <Routes>
      <Route path="/" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/verifymail" element={<EmailVerification />} />
        <Route path="/getstarted/flow-1" element={<MoreAboutYou />} />
        {/* <Route path="/complete-consumer" element={<CompleteConsumer />} />
        <Route path="/complete-contributor" element={<CompleteContributor />} /> */}
        <Route path="/forgotpassword" element={<ForgotPass />} />
        <Route path="/resetPassword" element={<ResetPass />} />
        <Route path="/login" element={<Login />} />

       
      </Routes>
  </Router>
  );
}

export default App;
