import React,{useState} from 'react';
import OnboardingSidebar from '../../Onboarding/Reusables/OnboardingSidebar/OnboardingSidebar';
import back from '../../../assets/backicon.svg';
import BaseButton from '../../Onboarding/Reusables/BaseButton/BaseButton';
import emailIcon from '../../../assets/emailIcon.svg';
import './ForgotPass.scss';
import axios from "axios";
import { useSelector } from 'react-redux';


const ForgotPass = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const userData = useSelector(state => state.userLogin.userInfo);
  const token = userData.token;
  const [loading,setLoading] = useState(false);
  const [email,setEmail]=useState('');

  const handleSubmit=(e)=>{
    e.preventDefault()
    let body={
      "email":email
    }
    try {
      setLoading(true)
      const config = {
        headers: {
          "Content-Type": "application/json",
           'Authorization': `Bearer ${token}`
        },
      };

      axios
        .post(`${baseUrl}auth/reset-password`,body,config)
        .then((res) => {
            console.log(res)
            alert("Reset Successful check your email")
            setLoading(false)
        })
        .catch((error) => {
          
          setLoading(false)
          if (error.response) {
            alert(error.response.data.message);
          } else if (error.request) {
            alert("Check your connection. Unable to reach the server.");
          } else {
            alert("An error occurred. Please try again later.");
          }
        });
    }
    catch (error) {
      console.log("error", error);
    }
  }


  return (
    <div className='onboard_cont'>

      <div>
         <OnboardingSidebar/>
      </div>

      <div className='forgot_pass_cont'>
          <div>
            <img 
              style={{marginBottom:'32px'}}
              src={back} 
              onClick={()=>window.location.href='/login'}
              alt="icon" />
          </div>

          <h1>Forgot Password</h1>
          <p>We will send you an OTP to recover your account</p>

          <form onSubmit={handleSubmit}>
          <div 
          style={{marginBottom:'20px'}}
          className='signup_email'>
                <h4> Enter your email</h4>
                <div>
                <img src={emailIcon} alt="icon" />
                <input 
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder='e.g. name@example.com'
                type="email"
                />
                </div>
                
          </div>

          {loading ?(
            <p style={{textAlign:'center'}}>Please wait...</p>
            ):(
          <BaseButton
               title={"Send Code"}
               width={"100%"}
               height={"44px"}
               padding={"0.5em 1em"}
          />)}
          </form>
      </div>

    </div>
  )
}

export default ForgotPass
