import React, {useState,useContext, useEffect} from 'react';
import Header from '../Header/Header';
import OnboardingSidebar from '../../Reusables/OnboardingSidebar/OnboardingSidebar';
import back from '../../../../assets/backicon.svg';
import './MoreAboutTwo.scss';
import { multiStepContext } from '../../Reusables/ProgressBar/StepContext';
import ProgressBar from '../../Reusables/ProgressBar/ProgressBar';
import checkIcon from '../../../../assets/checkIcon.svg';
import checkedIcon from '../../../../assets/checkedOrange.svg';
import BaseButton from '../../Reusables/BaseButton/BaseButton';
import axios from "axios";
import { useSelector } from 'react-redux';



const MoreAboutTwo = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [preferences,setPreferences]=useState([]);
  const [language, setLanguage]=useState('English')
  const { setStep } = useContext(multiStepContext);
  const [industries,setIndustries]=useState([])
  const userData = useSelector(state => state.userLogin.userInfo);
  const token = userData.token
  
  const isChecked = (item) => preferences.includes(item);
  const handleChange = (item) => {
    setPreferences((prevPreferences) => {
      // Determine the new preferences list
      const updatedPreferences = prevPreferences.includes(item.id)
        ? prevPreferences.filter((value) => value !== item.id)
        : [...prevPreferences, item.id];
  
      // Update sessionStorage with the new preferences list
      sessionStorage.setItem('preferences', JSON.stringify(updatedPreferences));
  
      // Return the updated preferences to update the state
      return updatedPreferences;
    });

    const storedPreferencesName = JSON.parse(sessionStorage.getItem('preferencesName')) || [];
    const updatedPreferencesName = storedPreferencesName.includes(item.name)
    ? storedPreferencesName.filter((value) => value !== item.name)
    : [...storedPreferencesName, item.name];

    // Save updated preferencesName to sessionStorage
    sessionStorage.setItem('preferencesName', JSON.stringify(updatedPreferencesName));
    
  };

  const handleLanguage=(item)=>{
    setLanguage(item)
    sessionStorage.setItem('language', item);
  }

  const getIndustry=()=>{
 
    try {
      
      const config = {
        headers: {
          "Content-Type": "application/json",
           'Authorization': `Bearer ${token}`
        },
      };

      axios
        .get(`${baseUrl}industry`,config)
        .then((res) => {
            console.log(res)
            setIndustries(res.data.data)
        
        })
        .catch((error) => {
          
         
          if (error.response) {
            alert(error.response.data.message);
          } else if (error.request) {
            alert("Check your connection. Unable to reach the server.");
          } else {
            alert("An error occurred. Please try again later.");
          }
        });
    }
    catch (error) {
      console.log("error", error);
    }
}


  const handleSubmit=()=>{
    if(preferences.length===0){
      alert('please choose prefernces')
      return
    }
    else{
      setStep(3)
    }
}

useEffect(()=>{
  getIndustry()

  const storedPreferences = sessionStorage.getItem('preferences');
  if (storedPreferences) {
    // Parse the stored JSON string to an array
    const parsedPreferences = JSON.parse(storedPreferences);
    setPreferences(parsedPreferences);
  }
  setLanguage(sessionStorage.getItem('language')||'english')
  // eslint-disable-next-line 
},[])

  return (
    <div className='onboard_cont'>
      <div>
        <OnboardingSidebar/>
      </div>

      <div className='more_about_two_cont'>
        <div style={{marginBottom:"32px"}}>
          <img 
          onClick={()=>setStep(1)}
          src={back} alt="icon" />
        </div>

        <Header/>  

        <div>
          <ProgressBar/>
        </div> 
        
        <div className='more_about_two_text'>
          <p>Set your preferences</p>
          <p>This helps to customize your Dashboard</p>
        </div>
       
        <p style={{marginBottom:'10px'}}>Select Language</p>
        <div className='more_about_prefer'>
         
        <div >
            <div className='more_about_imgch'>
              <img 
                onClick={() => handleLanguage('english')}
                src={language ==='english' ? checkedIcon : checkIcon}  
                alt="Icon" 
            />
            </div>
           
            <p>English</p>
          </div>

          <div >
            <div className='more_about_imgch'>
              <img 
                onClick={() => handleLanguage('portuguese')}
                src={language ==='portuguese' ? checkedIcon : checkIcon} 
                alt="Icon" 
              />
            </div>
            
            <p>Portuguese</p>
          </div>

          <div >
          <div className='more_about_imgch'>
            <img 
              onClick={() => handleLanguage('french')}
              src={language ==='french' ? checkedIcon : checkIcon}  
              alt="Icon" 
            />
            </div>
           
            <p>French</p>
          </div>
          
        </div>

        <p style={{marginBottom:'10px'}}>Select Industries</p>

        <div className='more_about_prefer'>
          
          {industries.map((item)=>
            <div>
              <div className='more_about_imgch'>
                <img 
                  onClick={() => handleChange(item)}
                  src={isChecked(item.id) ? checkedIcon : checkIcon} 
                  alt="Icon" 
                />
              </div>
              
              <p>{item.name}</p>
            </div>
          )}
          

      {/* <div >
            <div className='more_about_imgch'>
              <img 
                onClick={() => handleChange('Solar')}
                src={isChecked('Solar') ? checkedIcon : checkIcon} 
                alt="Solar Icon" 
            />
            </div>
           
            <p>Solar</p>
          </div>

          <div >
            <div className='more_about_imgch'>
              <img 
                onClick={() => handleChange('Gas')}
                src={isChecked('Gas') ? checkedIcon : checkIcon} 
                alt="Gas Icon" 
              />
            </div>
            
            <p>Gas</p>
          </div>

          <div >
          <div className='more_about_imgch'>
            <img 
              onClick={() => handleChange('Wind')}
              src={isChecked('Wind') ? checkedIcon : checkIcon} 
              alt="Wind Icon" 
            />
            </div>
           
            <p>Wind</p>
          </div>

          <div >
          <div className='more_about_imgch'>
            <img 
              onClick={() => handleChange('LNG')}
              src={isChecked('LNG') ? checkedIcon : checkIcon} 
              alt="LNG Icon" 
            />
            </div>
           
            <p>LNG</p>
          </div> */}
        </div>

          
       <div className='more_about_btn_div'>
        
               <BaseButton
                    title={"Proceed"}
                    width={"160px"}
                    height={"44px"}
                    padding={"0.5em 0.75em"}
                 
                    onClick={()=>handleSubmit()}
                />
        </div>

      </div>
    
    </div>
  )
}

export default MoreAboutTwo
