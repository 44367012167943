import React, { useState } from 'react';
import './SignUpPage.scss';
import emailIcon from '../../../../assets/emailIcon.svg';
import imgone from '../../../../assets/iconpadlock.svg';
import imgtwo from '../../../../assets/iconeyepasswor.svg';
import TermsandPrivacy from '../../Reusables/TermsandPrivacy/TermsandPrivacy';
import { useNavigate } from 'react-router-dom';
import BaseButton from '../../Reusables/BaseButton/BaseButton';
import axios from "axios";
import { useDispatch } from "react-redux";
import { USER_LOGIN_SUCCESS } from '../../../redux/userConstants';


const SignUpPage = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();
  const [firstName,setFirstName]= useState('');
  const [lastName,setLastName]= useState('');
  const [email,setEmail]= useState('');
  const [fPassword,setFirstPassword]= useState('');
  const [sPassword,setSecondPassword]= useState('');
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [loading,setLoading] = useState(false);
  const dispatch = useDispatch();
  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };
  
  
  const handleSubmit = (e) => {
  
    e.preventDefault()
    let body={  
        "first_name": firstName,
        "last_name": lastName,
        "email": email,
        "password": fPassword 
    }
    if(fPassword !== sPassword){
      alert("password do not match")
      return
    }
    else{
        setLoading(true)
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
  
        axios
          .post(`${baseUrl}auth/signup`,body,config)
          .then((res) => {
              console.log(res)
              
              const { user, token } = res.data.data;
              dispatch({
                type: USER_LOGIN_SUCCESS,
                payload: { user, token },  
              });   
              localStorage.setItem('userInfo', JSON.stringify({ user, token }));
              navigate("/verifymail")
              setLoading(false)
          })
          .catch((error) => {
              setLoading(false)
           
            if (error.response) {
              alert(error.response.data.message);
            } else if (error.request) {
              alert("Check your connection. Unable to reach the server.");
            } else {
              alert("An error occurred. Please try again later.");
            }
          });
      }
      catch (error) {
        console.log("error", error);
      }
    }
     
  };

  // const =()=> {
  //   navigate('/verifymail');
  // }

  return (
    <div className='signup_container'>

      <h3> Create a Sonaroid Account</h3>

      <div className='existing_account'>
        <h4>Existing account?</h4>
        <h4
          onClick={()=>window.location.href="/login"}
          style={{cursor:'pointer'}}
        >Login</h4>
      </div>

      <form 
        className='signup_form' 
        onSubmit={handleSubmit}
      >
        
        <div className='signup_name'>
            <div>
                <h4>First name</h4>
                <div className='name_input' >
                <input 
                required
                placeholder='e.g Jane'
                type="text"
                value={firstName}
                maxLength={50} 
                onChange={(e) => setFirstName(e.target.value)}
                />
                </div>
               
            </div>
            <div>
                <h4>Last name</h4>
                <div className='name_input'>
                <input 
                required
                placeholder='e.g Doe'
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                maxLength={50} />
                </div>
               
            </div>
        </div>



        <div className='signup_email'>
            <h4> Enter your email</h4>
            <div>
            <img src={emailIcon} alt="icon" />
            <input 
            required
            placeholder='e.g. name@example.com'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="email" name="" id=""
            />
            </div>
           
        </div>

        <div className='signup_pass'>
            <h4>Create password</h4>
            <div>
            <img src={imgone} alt="icon" />
             <input
              required
              type={isPasswordVisible ? 'text' : 'password'}
              value={fPassword}
              onChange={(e) => setFirstPassword(e.target.value)}
              min={8} 
              maxLength={20}
              />
              
             <img 
              onClick={()=>togglePasswordVisibility()} 
              style={{ cursor: 'pointer' }}
             src={imgtwo} alt="icon" />
          </div>
            
        </div>
        <p className='helper_text'>8-20 characters, no spaces</p>

        <div className='signup_pass'>
            <h4>Confirm password</h4>
            <div>
            <img src={imgone} alt="icon" />
             <input
              required
              value={sPassword}
              onChange={(e) => setSecondPassword(e.target.value)}
              type={isPasswordVisible ? 'text' : 'password'}
              min={8} 
              maxLength={20}
              />
             <img 
              onClick={()=>togglePasswordVisibility()} 
              style={{ cursor: 'pointer' }}
              src={imgtwo} 
              alt="icon" />
            </div>
            
        </div>

        <p className='helper_text'>8-20 characters, no spaces</p>
        
        {loading ?(
          <p style={{textAlign:'center'}}>Please wait...</p>
        ):(
        
        <BaseButton
            title={" Continue"}
            width={"100%"}
            height={"44px"}
            padding={"0.5em 1em"}
           
        />)}
        

       
      </form>
      <TermsandPrivacy/>
    </div>
  )
}

export default SignUpPage
