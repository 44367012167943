import React, { useContext } from 'react';
import { multiStepContext } from './StepContext';
import './ProgressBar.scss';

const ProgressBar = () => {
  const { currentstep } = useContext(multiStepContext);

  return (
    <div className="progress-container">
      {[1, 2, 3, 4].map((step) => (
        <div
          key={step}
          className={`progress-bar ${step <= currentstep ? 'active' : 'inactive'}`}
        />
      ))}
    </div>
  );
};

export default ProgressBar;
