import React from 'react';
import logo from '../../../../assets/Logo.svg';
import './OnboardingSidebar.scss';
import check from '../../../../assets/check.svg';
import iconimg from '../../../../assets/blessing.png'
const OnboardingSidebar = () => {
  return (
    <div className='onboard_sidebar'>
        <div className='onboard_sidebar_logo'>
        <img src={logo} alt="" />
        </div>
        
        <h1>
            We are changing the narrative of African energy.
        </h1>

        <div className='section_two'>
            <div>
            <div className='check'>
                <img src={check} alt="icon" />
                </div>
               
                <p>Treasure Trove of Data</p>
            </div>

            <div>
                <div className='check'>
                <img src={check} alt="icon" />
                </div>
                <p>User-Friendly Interface</p>
            </div>

            <div>
            <div className='check'>
                <img src={check} alt="icon" />
                </div>
                <p>Data Credibility</p>
            </div>

            <div>
                <div className='check'>
                    <img src={check} alt="icon" />
                </div>
                <p>Contribution Potential</p>
            </div>
        </div>

        <div className='section_three'>
            <p>Sonaroid rocks!  Found exactly the African energy data I needed in minutes. Great platform, easy to use.  Highly recommend! #SonaroidFan #DataForGood</p>
                <div className='quote-speaker'>
                    <div style={{width:'40px'}}>
                        <img src={iconimg} alt="icon" />
                    </div>
                  
                    <div>
                        <h4>Blessing Ojediran</h4>
                        <p style={{fontSize:'0.875rem'}}>blessing@email.com</p>
                    </div>
                </div>
        </div>
       
     
    </div>
  )
}

export default OnboardingSidebar
