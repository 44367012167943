import React,{ useState,useContext,useEffect} from 'react';
import OnboardingSidebar from '../Reusables/OnboardingSidebar/OnboardingSidebar';
import ProfileCard from '../Reusables/ProfileCard/ProfileCard';
import './CompleteConsumer.scss';
import { FaArrowLeftLong } from "react-icons/fa6";
import BaseButton from '../Reusables/BaseButton/BaseButton';
import backIcon from "../../../assets/backicon.svg";
import CompletePage from '../Reusables/CompletePage/CompletePage';
import cupImage from '../../../assets/cup.png';
import { multiStepContext} from '../Reusables/ProgressBar/StepContext';
import { useSelector } from "react-redux";
import { formatDateString } from '../Reusables/dateIsoFormat';
import axios from "axios";

const CompleteConsumer = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const userData = useSelector(state => state.userLogin.userInfo);
  const token = userData.token
  const firstName = userData.user.first_name;
  const lastName = userData.user.last_name;
  const fullName = firstName + " " + lastName;
  const datejoined = userData.user.created_at
  const [submitTrue,setSubmitTrue] = useState(false);
  const { setStep } = useContext(multiStepContext);
  const [selectedUserType, setSelectedUserType] = useState('');
  const [image, setImage] = useState(null);
  const [apiImage,setApiImage]=useState(null);
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedAccType, setSelectedAccType] = useState('');
  const [selectedCountry,setSelectedCountry]=useState('');
  const [preferences,setPreferences]=useState([])
  const [preferencesName,setPreferencesName]=useState([])
  const [companyName,setCompanyName]=useState('');
  const [companyRole,setCompanyRole] = useState('');
  const [companySize,setCompanySize] = useState(null);
  const [selectedIndustry, setSelectedIndustry] = useState('');
  

  const backtoConsumer=()=>{
      setStep(3);
  }

  const handleSubmit=()=>{

    if(selectedUserType==="Individual"){
      let body={
      
        "image_url": apiImage,
        "user_type":selectedUserType,
        "occupation": selectedOption,
        "account_type": selectedAccType,
        "industries": preferences,
        "location": selectedCountry,
      }
      try {
      
        const config = {
          headers: {
            "Content-Type": "application/json",
             'Authorization': `Bearer ${token}`
          },
        };
  
        axios
          .post(`${baseUrl}auth/onboarding`,body,config)
          .then((res) => {
              console.log(res)
              setSubmitTrue(true)
          })
          .catch((error) => {
            
           
            if (error.response) {
              alert(error.response.data.message);
            } else if (error.request) {
              alert("Check your connection. Unable to reach the server.");
            } else {
              alert("An error occurred. Please try again later.");
            }
          });
      }
      catch (error) {
        console.log("error", error);
      }
    }
    else if(selectedUserType==="corporate"){
      let body={
      
        "image_url": apiImage,
        "user_type":selectedUserType,
        "location": selectedCountry,
        "account_type": selectedAccType,
        "industries": preferences,
        "occupation":companyRole,
        "company_name": companyName,
        "company_size": companySize,
        "company_industry": selectedIndustry,
      }
      try {
      
        const config = {
          headers: {
            "Content-Type": "application/json",
             'Authorization': `Bearer ${token}`
          },
        };
  
        axios
          .post(`${baseUrl}auth/onboarding`,body,config)
          .then((res) => {
              console.log(res)
              setSubmitTrue(true)
          })
          .catch((error) => {
            
           
            if (error.response) {
              alert(error.response.data.message);
            } else if (error.request) {
              alert("Check your connection. Unable to reach the server.");
            } else {
              alert("An error occurred. Please try again later.");
            }
          });
      }
      catch (error) {
        console.log("error", error);
      }
    }

    
  }

  useEffect(()=>{
   
    setImage(sessionStorage.getItem('image') || null)
    setApiImage(sessionStorage.getItem('apiImage') || null)
    setSelectedUserType(sessionStorage.getItem('user_type') || '')
    setSelectedOption(sessionStorage.getItem('occupation') || '')
    setSelectedCountry(sessionStorage.getItem('country') || '')
    setSelectedAccType(sessionStorage.getItem('account_type') || '');
    const storedPreferences = sessionStorage.getItem('preferences');
    if (storedPreferences) {
      // Parse the stored JSON string to an array
      const parsedPreferences = JSON.parse(storedPreferences);
      setPreferences(parsedPreferences);
    }
    const storedPreferencesName = sessionStorage.getItem('preferencesName');
    if (storedPreferences) {
      // Parse the stored JSON string to an array
      const parsedPreferences = JSON.parse(storedPreferencesName);
      setPreferencesName(parsedPreferences);
    }
    setCompanySize(sessionStorage.getItem('companySize') || null)
    setCompanyName(sessionStorage.getItem('companyName') || '');
    setCompanyRole(sessionStorage.getItem('companyRole') || '');
    setSelectedIndustry(sessionStorage.getItem('industry') || '')
    // eslint-disable-next-line 
  },[])



  return (
    <div className='onboard_cont'>

        <div>
            <OnboardingSidebar/>
        </div>

        <div className='flow_complete_cont'>
        {submitTrue ? (
          <CompletePage
          imagePic={cupImage}
          header={"You’re all caught up!"}
          subtext={"You have finished setting up your account."}
          btntext={"Go to Dashboard"}
          width={"400px"}
          height={"56px"}
          padding={"1em 1.5em"}
          />
        ):(
        
          <>
          <img 
          style={{marginBottom:'32px'}}
          onClick={()=>backtoConsumer()}
          src={backIcon} 
          alt="icon" />

          <ProfileCard
          callSubmit={handleSubmit}
          accountType={selectedAccType}
          accountinfo={selectedOption}
          accountloc={selectedCountry}
          accountname={fullName}
          preferences={preferencesName}
          datejoined={formatDateString(datejoined)}
          subscription={"Subscription"}
          subtype={"Free"}
          cardImage={image}
          />

          <div className='flow_complete_btm'>
            <div className='flow_complete_back'>
              <FaArrowLeftLong color='#4B4F57'/>
              <h4>Back</h4>
            </div>
            <div>
              <BaseButton
                onClick={()=>handleSubmit()}
                type={"submit"}
                width={'88px'}
                height= {'44px'}
                padding= {'0.5em 1em'}
                title={"Submit"}
              />
              
            </div>
          </div>
          </>
    
        )}
          </div>
       
       
    </div>
  )
}

export default CompleteConsumer
