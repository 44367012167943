import React from 'react'
import './Header.scss'
const Header = () => {
  return (
    <div className='get_started_header'>
      <h2>Let’s get you started!</h2>
      <p>We just need some information to set up your account.</p>
    </div>
  )
}

export default Header
