import React,{useState,useContext,useEffect} from 'react';
import OnboardingSidebar from '../Reusables/OnboardingSidebar/OnboardingSidebar';
import ProfileCard from '../Reusables/ProfileCard/ProfileCard';
import backIcon from "../../../assets/backicon.svg";
import './CompleteContributor.scss';
import coffee from '../../../assets/Groupcoffee.png'
import CompletePage from '../Reusables/CompletePage/CompletePage';
import { multiStepContext } from '../Reusables/ProgressBar/StepContext';
import { useSelector } from "react-redux";
import { formatDateString } from '../Reusables/dateIsoFormat';
import axios from "axios";

const CompleteContributor = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const userData = useSelector(state => state.userLogin.userInfo);
  const token = userData.token
  const firstName = userData.user.first_name;
  const lastName = userData.user.last_name;
  const fullName = firstName + " " + lastName;
  const datejoined = userData.user.created_at

  const [selectedUserType, setSelectedUserType] = useState('');
  const [image, setImage] = useState(null);
  const [apiImage,setApiImage]=useState(null);
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedAccType, setSelectedAccType] = useState('');
  const [selectedCountry,setSelectedCountry]=useState('');
  const [preferences,setPreferences]=useState([])
  const [preferencesName,setPreferencesName]=useState([])
  const [companyName,setCompanyName]=useState('');
  const [companyRole,setCompanyRole] = useState('');
  const [companySize,setCompanySize] = useState(null);
  const [selectedIndustry, setSelectedIndustry] = useState('');
  const [submitTrue,setSubmitTrue] = useState(false);
  const { setStep } = useContext(multiStepContext);
  const [bio,setBio]=useState('');
  const [experience,setExperience]=useState('');
  const [dataaccess,setDataAccess]=useState('');
 
  const backToFour =()=>{
    setStep(4)
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
  });
  }

  const handleSubmit=()=>{

    if(selectedUserType==="Individual"){
      let body={
      
        "image_url": apiImage,
        "user_type":selectedUserType,
        "occupation": selectedOption,
        "account_type": selectedAccType,
        "industries": preferences,
        "location": selectedCountry,
        "bio": bio,
        "previous_experience": experience,
        "data_source": dataaccess,
        "document_url": "string"
      }
      try {
      
        const config = {
          headers: {
            "Content-Type": "application/json",
             'Authorization': `Bearer ${token}`
          },
        };
  
        axios
          .post(`${baseUrl}auth/onboarding`,body,config)
          .then((res) => {
              console.log(res)
              setSubmitTrue(true)
          })
          .catch((error) => {
            
           
            if (error.response) {
              alert(error.response.data.message);
            } else if (error.request) {
              alert("Check your connection. Unable to reach the server.");
            } else {
              alert("An error occurred. Please try again later.");
            }
          });
      }
      catch (error) {
        console.log("error", error);
      }
    }
    else if(selectedUserType==="corporate"){
      let body={
      
        "image_url": apiImage,
        "user_type":selectedUserType,
        "location": selectedCountry,
        "account_type": selectedAccType,
        "industries": preferences,
        "occupation":companyRole,
        "company_name": companyName,
        "company_size": companySize,
        "company_industry": selectedIndustry,
        "bio": bio,
        "previous_experience": experience,
        "data_source": dataaccess,
        "document_url": "string"
      }
      try {
      
        const config = {
          headers: {
            "Content-Type": "application/json",
             'Authorization': `Bearer ${token}`
          },
        };
  
        axios
          .post(`${baseUrl}auth/onboarding`,body,config)
          .then((res) => {
              console.log(res)
              setSubmitTrue(true)
          })
          .catch((error) => {
            
           
            if (error.response) {
              alert(error.response.data.message);
            } else if (error.request) {
              alert("Check your connection. Unable to reach the server.");
            } else {
              alert("An error occurred. Please try again later.");
            }
          });
      }
      catch (error) {
        console.log("error", error);
      }
    }

    
  }

  useEffect(()=>{
   
    setImage(sessionStorage.getItem('image') || null)
    setApiImage(sessionStorage.getItem('apiImage') || null)
    setSelectedUserType(sessionStorage.getItem('user_type') || '')
    setSelectedOption(sessionStorage.getItem('occupation') || '')
    setSelectedCountry(sessionStorage.getItem('country') || '')
    setSelectedAccType(sessionStorage.getItem('account_type') || '');
    const storedPreferences = sessionStorage.getItem('preferences');
    if (storedPreferences) {
      // Parse the stored JSON string to an array
      const parsedPreferences = JSON.parse(storedPreferences);
      setPreferences(parsedPreferences);
    }
    const storedPreferencesName = sessionStorage.getItem('preferencesName');
    if (storedPreferences) {
      // Parse the stored JSON string to an array
      const parsedPreferences = JSON.parse(storedPreferencesName);
      setPreferencesName(parsedPreferences);
    }
    setCompanySize(sessionStorage.getItem('companySize') || null)
    setCompanyName(sessionStorage.getItem('companyName') || '');
    setCompanyRole(sessionStorage.getItem('companyRole') || '');
    setSelectedIndustry(sessionStorage.getItem('industry') || '')
    setBio(sessionStorage.getItem('bio') || '');
    setExperience(sessionStorage.getItem('experience') || '');
    setDataAccess(sessionStorage.getItem('data') || '')
    // eslint-disable-next-line 
  },[])




  return (
    <div className='onboard_cont'>
      <div>
        <OnboardingSidebar/>
      </div>

     
        <div className='complete_contributor_cont'>
        {submitTrue ?(
         <CompletePage
         imagePic={coffee}
         header={"Your account is pending approval!"}
         subtext={"Your contributor’s profile must be approved by our administrators before you can make contributions on the platform."}
         btntext={"Go to Dashboard"}
         width={"400px"}
         height={"56px"}
         padding={"1em 1.5em"}
         />
      ):(
        <>
        <div>
          <img 
            style={{marginBottom:'32px'}}
            onClick={()=>backToFour()}
            src={backIcon} 
            alt="icon" />
        </div>

        <div>
            <ProfileCard
                callSubmit={handleSubmit}
                cardImage={image}
                accountType={selectedAccType}
                accountinfo={selectedOption}
                accountloc={selectedCountry}
                accountname={fullName}
                preferences={preferencesName}
                datejoined={formatDateString(datejoined)}
                subscription={"User type"}
                subtype={selectedUserType}
            />
          </div>

        <div className='credibility_box'>
          <h4 className='credibility_box_header'>Credibility Check</h4>

          <div className='credibility_box_content'>
            <h4>A short bio of yourself</h4>
            <p>My name is Blessing, and I am a Data Analyst with 7 years of experience in the African energy sector. I have a strong passion for ensuring data accessibility and driving sustainable energy development through data-driven decision making. My expertise lies in renewable energy resource assessment and data collection and analysis for power grids.</p>
          </div>

          <div className='credibility_box_content'>
            <h4>Your previous experience as a contributor to energy data</h4>
            <p>My name is Blessing, and I am a Data Analyst with 7 years of experience in the African energy sector. I have a strong passion for ensuring data accessibility and driving sustainable energy development through data-driven decision making. My expertise lies in renewable energy resource assessment and data collection and analysis for power grids.</p>
          </div>

          <div className='credibility_box_content'>
            <h4>How do you have access to the data you will provide?</h4>
            <p>My name is Blessing, and I am a Data Analyst with 7 years of experience in the African energy sector. I have a strong passion for ensuring data accessibility and driving sustainable energy development through data-driven decision making. My expertise lies in renewable energy resource assessment and data collection and analysis for power grids.</p>
          </div>

          <div className='credibility_box_content'>
            <h4>Upload supporting documents to back your claims</h4>
            <p>My name is Blessing, and I am a Data Analyst with 7 years of experience in the African energy sector. I have a strong passion for ensuring data accessibility and driving sustainable energy development through data-driven decision making. My expertise lies in renewable energy resource assessment and data collection and analysis for power grids.</p>
          </div>

        </div>
        </>
    
      )}
      </div>

    </div>
  )
}

export default CompleteContributor
