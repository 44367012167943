import React, { useState } from 'react'
import emailIcon from '../../../assets/emailIcon.svg';
import imgone from '../../../assets/iconpadlock.svg';
import imgtwo from '../../../assets/iconeyepasswor.svg'
import BaseButton from '../../Onboarding/Reusables/BaseButton/BaseButton';
import OnboardingSidebar from '../../Onboarding/Reusables/OnboardingSidebar/OnboardingSidebar';
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { USER_LOGIN_SUCCESS } from '../../redux/userConstants';
import axios from "axios";
import './Login.scss';
import checkIcon from '../../../assets/checkIcon.svg';
import checkedIcon from '../../../assets/checkedIcon.svg';
const Login = () => {

  const [remember,setRemember] = useState(false);
  const [email,setEmail]= useState('');
  const [password,setPassword]= useState('');
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [loading,setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };
  const toggleRemember=()=>{
    setRemember(!remember)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    let body={  
      
        "email": email,
        "password": password 
    }
    
  
       
      try {
        setLoading(true)
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
  
        axios
          .post(`${baseUrl}auth/signin`,body,config)
          .then((res) => {
              
              
              const { user, token } = res.data.data;
              dispatch({
                type: USER_LOGIN_SUCCESS,
                payload: { user, token },  
              });   
              localStorage.setItem('userInfo', JSON.stringify({ user, token }));
              if(user.isVerified === false){
                navigate("/verifymail")
              }
              else if(user.isVerified === true && user.user_status==="onboarding"){
                navigate("/getstarted/flow-1")
              }
            
              setLoading(false)
          })
          .catch((error) => {
              setLoading(false)
            console.log(error)
            if (error.response) {
              alert(error.response.data.message);
            } else if (error.request) {
              alert("Check your connection. Unable to reach the server.");
            } else {
              alert("An error occurred. Please try again later.");
            }
          });
      }
      catch (error) {
        console.log("error", error);
      }
    
     
  };

  return (

    <div className='onboard_cont'>
        <div>
            <OnboardingSidebar/>
        </div>

        <div className='login_page_cont'>

            <div className='login_header'>
                <h3>Login</h3>
                <p>New account? <span><a href="/signup">Sign Up</a></span></p>
            </div>
           

            <form
              onSubmit={handleSubmit}
            >
            <div className='signup_email'>
                <h4> Enter your email</h4>
                <div>
                <img src={emailIcon} alt="icon" />
                <input 
                required
                placeholder='e.g. name@example.com'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email" 
                />
                </div>
                
            </div>
            <div className='signup_pass'>
                <h4>Enter password</h4>
                <div>
                <img src={imgone} alt="icon" />
                <input
                required
                type={isPasswordVisible ? 'text' : 'password'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                />
                <img 
                 onClick={()=>togglePasswordVisibility()} 
                 style={{ cursor: 'pointer' }}
                src={imgtwo} alt="icon" />
                </div>
                
            </div>

            <div className='remember_box'>
                <div className='remember_box_one'>
                    <img 
                    onClick={()=>toggleRemember()}
                    src={remember?checkedIcon:checkIcon} 
                    alt="" 

                    />
                    <p>Remember me</p>
                </div>
                <div 
                style={{cursor:'pointer'}}
                onClick={()=>window.location.href="/forgotpassword"}
                className='remember_box_two'>
                    <p>Forgot password</p>
                </div>
            </div>
            {loading ?(
            <p style={{textAlign:'center'}}>Please wait...</p>
            ):(
        
            <BaseButton
                title={"Login"}
                width={"380px"}
                height={"44px"}
                padding={"0.5em 1em"}
            />)}
            </form>
        </div>
      
    </div>
  )
}

export default Login
