import React,{useState,useContext,useEffect} from 'react';
import OnboardingSidebar from '../../Reusables/OnboardingSidebar/OnboardingSidebar';
import Header from '../Header/Header';
import ProgressBar from '../../Reusables/ProgressBar/ProgressBar';
import CustomDropdown from '../../Reusables/SelectDropdown/SelectDropdown';
import radioOne from '../../../../assets/radio.svg';
import radioTwo from '../../../../assets/radioCh.svg';
import './MoreAboutThree.scss'
import back from '../../../../assets/backicon.svg';
import { multiStepContext } from '../../Reusables/ProgressBar/StepContext';
import BaseButton from '../../Reusables/BaseButton/BaseButton';
import axios from "axios";

const MoreAboutThree = () => {
    const { setStep } = useContext(multiStepContext);
    const [selectedUserType, setSelectedUserType] = useState('consumer');
    const [options, setOptions]=useState([])
    const [selectedCountry,setSelectedCountry]=useState('')
    
    
    const handleOptionSelect = (option) => {
        setSelectedCountry(option);
        sessionStorage.setItem('country',option);
    };
    
    const fetchCountryNames = async () => {
        try {
          
          const response = await axios.get('https://restcountries.com/v3.1/all');
          const names = response.data.map((country) => country.name.common);
          const sortedNames = names.sort((a, b) => a.localeCompare(b));
          setOptions(sortedNames);
         
        } catch (err) {
          // Handle any errors
            alert(err)
        }
    }
  
    const handleSelect = (userType) => {
        setSelectedUserType(userType);
        sessionStorage.setItem('account_type', userType);
      };

    const handleSubmit =()=>{
        if(selectedUserType === 'consumer'){
            setStep(5)
        }
        else{
            setStep(4)
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        }
    }

    useEffect(() => {
        fetchCountryNames();
        setSelectedCountry(sessionStorage.getItem('country') || '')
        setSelectedUserType(sessionStorage.getItem('account_type') || sessionStorage.setItem('account_type', 'consumer'));
      }, []); 
    

  return (
    <div className='onboard_cont'>
      <div>
        <OnboardingSidebar/>
      </div>
      <div className='more_about_three_cont'>

      <div style={{marginBottom:"32px"}}>
          <img 
          onClick={()=>setStep(2)}
          src={back} alt="icon" />
        </div>
        <Header/>

        <div>
            <ProgressBar/> 
        </div>
       

       <div>

        <p className='more_about_three_wdyl'>Where do you live? </p>

        <CustomDropdown
            placeholder={'Nigeria'}
            heightFor={'44px'}
            heightForTwo={'233px'}
            paddingFor={'.5em 1em'}
            marginFor={"10px"}
            selectedOption={selectedCountry}
            onSelect={handleOptionSelect}
            options={options}
        />

        <div className='more_about_account_type'>
            <p>Account type</p>

                <div
               
                >
                    <img 
                     onClick={()=>handleSelect('consumer')}
                    src={selectedUserType === 'consumer'? radioTwo : radioOne} 
                    alt="" />
                    <h4>Consumer<span>(You intend to use data)</span></h4> 
                </div>
                <div
               
                >
                    <img 
                     onClick={()=>handleSelect('contributor')}
                     src={selectedUserType === 'contributor'? radioTwo : radioOne} 
                    alt="" />
                    <h4> Contributor <span>(You intend to upload data)</span></h4> 
                </div>
                <div
                onClick={()=>setSelectedUserType('both')}
                >
                    <img 
                     src={selectedUserType === 'both'? radioTwo : radioOne} 
                    alt="" />
                    <h4>Both<span> (You intend to use and upload data)</span></h4> 
                </div>
            </div>

            <div className='more_about_btn_div'>
                <BaseButton
                    title={" Complete"}
                    width={"160px"}
                    height={"44px"}
                    padding={"0.5em 0.75em"}
                    onClick={()=>handleSubmit()}
                />
               
            </div>
           
       </div>

      </div>
         
    </div>
  )
}

export default MoreAboutThree
