import React from 'react';
import BaseButton from '../BaseButton/BaseButton';
import './CompletePage.scss'

const CompletePage = ({
    imagePic,
    header,
    subtext,
    btntext,
    width,
    height,
    padding
    }) => {

  return (
    <div className='complete_page_cont'>
        <h3>{header}</h3>
        <p>{subtext}</p>

        <div className='complete_page_img'>
            <img src={imagePic} alt="" />
        </div>

        <BaseButton
          
            width={width}
            height= {height}
            padding= {padding}
            title={btntext}
          
        />
        
    </div>
  )
}

export default CompletePage
