import React ,{useContext}from 'react'
import MoreAboutOne from './MoreAboutOne/MoreAboutOne';
import MoreAboutTwo from './MoreAboutTwo/MoreAboutTwo';
import { multiStepContext } from '../Reusables/ProgressBar/StepContext';
import MoreAboutThree from './MoreAboutThree/MoreAboutThree';
import MoreAboutFour from './MoreAboutFour/MoreAboutFour';
import CompleteContributor from '../CompleteContributor/CompleteContributor';
import CompleteConsumer from '../CompleteConsumer/CompleteConsumer';
const MoreAboutYou = () => {
    const { currentstep } = useContext(multiStepContext);
   
    return (
      <div>
        {currentstep === 1 && <MoreAboutOne />}
        {currentstep === 2 && <MoreAboutTwo />}
        {currentstep === 3 && <MoreAboutThree />}
        {currentstep === 4 && <MoreAboutFour />}
        {currentstep === 5 && <CompleteConsumer />}
        {currentstep === 6 && <CompleteContributor />}
      </div>
    );
}

export default MoreAboutYou
